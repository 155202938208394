






























import Vue from "vue";
import { Component } from "vue-property-decorator";

import api from "@/core/utils/api";
import { BaselineSession } from "@/core/models";
import BaselineSessionUpload from "./BaselineSessionUpload.vue";

@Component({ components: { BaselineSessionUpload } })
export default class BaselineSessions extends Vue {
  loading = false;
  list: BaselineSession[] = [];

  async get() {
    this.loading = true;
    try {
      const end = "/api/Sessions/GetBaselineSessionsOfUser";
      this.list = (await api.get(end)) as BaselineSession[];
      this.list.map(x => {
        x.uploadedDate = new Date(x.uploadedDate);
        x.averageVolume = Math.round(x.averageVolume * 100);
        x.averagePitch = Math.round(x.averagePitch * 100) / 100;
        return x;
      });
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async remove(id: number) {
    this.loading = true;
    try {
      const end = `/api/Sessions/${id}`;
      await api.delete(end);
      const idx = this.list.findIndex(x => x.id === id);
      if (idx === -1) return;
      this.list.splice(idx, 1);
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  get headers() {
    return [
      { text: "ID", sortable: false, value: "id" },
      { text: "Name", sortable: true, value: "name" },
      {
        text: this.$t("settings.upAt"),
        sortable: true,
        value: "uploadedDate",
      },
      {
        text: this.$t("settings.avgVol"),
        sortable: true,
        value: "averageVolume",
      },
      {
        text: this.$t("settings.avgPitch"),
        sortable: true,
        value: "averagePitch",
      },
      {
        text: this.$t("settings.actions"),
        sortable: false,
        value: "actions",
      },
    ];
  }

  created() {
    this.get();
  }
}

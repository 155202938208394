




















































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

@Component
export default class PfpController extends Vue {
  @Getter("profile/pfp") pfp!: string;
  @Getter("profile/isDefaultPfp") isDefaultPfp!: boolean;
  @Action("profile/uploadPfp") uploadPfp!: (file: File) => Promise<void>;
  @Action("profile/removePfp") removePfp!: () => Promise<void>;

  loading = false;
  file: File | null = null;
  filePreview: string | null = null;
  squareImage = true;

  handleFileChange(ev: any) {
    const files = (ev.target.files || []) as FileList;
    const file = files[0];
    if (!file) {
      this.filePreview = null;
      return;
    }
    const img = new Image();
    const reader = new FileReader();
    reader.onload = () => {
      this.filePreview = reader.result as string;
      img.src = reader.result as string;
      img.onload = () => {
        this.squareImage = Math.abs(img.width / img.height - 1) < 0.1;
      };
    };
    reader.readAsDataURL(file);

    this.file = file;
  }
  async handleUpload() {
    if (!this.file) {
      const el = this.$refs.fileInput as HTMLInputElement;
      el.click();
      return;
    }
    this.loading = true;
    await this.uploadPfp(this.file);
    this.loading = false;
    this.cancelChange();
  }
  cancelChange() {
    this.file = null;
    this.filePreview = null;
    this.squareImage = true;
  }
  async removeImage() {
    this.loading = true;
    await this.removePfp();
    this.loading = false;
  }

  get pfpurl() {
    if (this.filePreview) return this.filePreview;
    return this.pfp;
  }
}

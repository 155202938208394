






























































import Vue from "vue";
import { SelectItem } from "@/core/models";
import { Getter, Action } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

@Component
export default class BaselineSessionUpload extends Vue {
  @Getter("sessions/baselineLoading") loading!: boolean;
  @Action("displaySnackbar") displaySnackbar!: Function;
  @Action("sessions/uploadBaseline") uploadBaseline!: Function;

  dialog = false;
  locale = this.$i18n.locale;
  @Watch("$i18n.locale", { immediate: true })
  langChanged() {
    this.locale = this.$i18n.locale;
  }

  localeItems: SelectItem[] = [
    { text: "English", value: "en" },
    { text: "Deutsch", value: "de" },
  ];

  file1: File | null = null;
  file2: File | null = null;
  file3: File | null = null;
  uploadCancel() {
    this.dialog = false;
  }
  async uploadConfirm() {
    if (!this.file1 || !this.file2 || !this.file3) {
      const msg = this.$t("forms.missing").toString();
      this.displaySnackbar(msg);
      return;
    }

    const data = new FormData();
    data.append("locale", this.locale);
    data.append("file1", this.file1);
    data.append("file2", this.file2);
    data.append("file3", this.file3);
    this.dialog = false;
    await this.uploadBaseline(data);
  }
}

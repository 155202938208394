import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"elevation":"0","color":"primary","loading":_vm.loading,"disabled":_vm.loading}},on),[_c('span',[_vm._v(_vm._s(_vm.$t("baseline.up")))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.uploadConfirm($event)}}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.$t("baseline.base")))])]),_c(VCardText,[_c('div',[_vm._v(_vm._s(_vm.$t("baseline.text")))]),_c(VFileInput,{attrs:{"accept":"audio/*, *.m4a","label":_vm.$t('baseline.base1')},model:{value:(_vm.file1),callback:function ($$v) {_vm.file1=$$v},expression:"file1"}}),_c(VFileInput,{attrs:{"accept":"audio/*, *.m4a","label":_vm.$t('baseline.base2')},model:{value:(_vm.file2),callback:function ($$v) {_vm.file2=$$v},expression:"file2"}}),_c(VFileInput,{attrs:{"accept":"audio/*, *.m4a","label":_vm.$t('baseline.base3')},model:{value:(_vm.file3),callback:function ($$v) {_vm.file3=$$v},expression:"file3"}}),_c(VSelect,{attrs:{"items":_vm.localeItems,"label":_vm.$t('baseline.lang')},model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"main--text",attrs:{"elevation":"0","color":"accent"},on:{"click":_vm.uploadCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VBtn,{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.uploadConfirm}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.submit")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
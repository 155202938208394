import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{staticStyle:{"width":"100%"},attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.list,"footer-props":{ itemsPerPageText: _vm.$t('settings.items_per_page_text') }},scopedSlots:_vm._u([{key:"item.uploadedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format")(item.uploadedDate))+" ")]}},{key:"item.averageVolume",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.averageVolume)+"%")])]}},{key:"item.averagePitch",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.averagePitch)+"Hz")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c(VSpacer),_c(VIcon,{on:{"click":function($event){return _vm.remove(item.id)}}},[_vm._v("mdi-delete")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }